import IconButton from '@/components/IconButton';
import { HeaderBackground } from '../components/HeaderBackground';
import clsx from 'clsx';
import HamburgerMenu from '../components/HamburgerMenu';
import { hamburgerMenuLinks } from '@/utils/links';

interface ProfileHeaderProps {
  currentUserFullName: string | undefined;
  onBackClick: () => void;
}

export function ProfileHeader({
  currentUserFullName,
  onBackClick,
}: ProfileHeaderProps) {
  const isLoadingUser =
    currentUserFullName === undefined || currentUserFullName === '';

  return (
    <HeaderBackground backgroundColourStyle='secondary'>
      <div className='absolute w-full b-0 z-2 p-10 text-white'>
        <div className='grid grid-cols-[auto_1fr_auto] items-center'>
          <IconButton type='CHEVRON_LEFT' onClick={onBackClick} theme='DARK' />
          <p className='text-center text-sm'>PROFILE</p>
          <HamburgerMenu
            navLinks={hamburgerMenuLinks}
            iconTheme='DARK'
            showLogoutOption
          />
        </div>
        <div className='flex justify-center'>
          <p
            className={clsx(
              'w-full text-center text-xl text-clip',
              isLoadingUser &&
                'animate-[pulse_2s_ease-in-out_infinite] bg-slate-200 opacity-70 w-[50%] h-9 rounded-md'
            )}
          >
            {currentUserFullName}
          </p>
        </div>
      </div>
    </HeaderBackground>
  );
}
