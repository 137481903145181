import { Button as DaisyButton } from 'react-daisyui';
import { HiArrowUpRight } from 'react-icons/hi2';
import { ButtonProps } from './Button';

type Variant = 'white' | 'black';

type ArrowButtonProps = ButtonProps & { buttonVariant: string };

const UpRightArrow = '';

export default function ArrowButton(props: ArrowButtonProps): JSX.Element {
  return (
    <DaisyButton
      className={`
            rounded-full
            font-bold
            border-none
            ${
              props.buttonVariant == 'white'
                ? 'bg-white hover:bg-white'
                : 'btn-black'
            }
          `}
      shape='circle'
      {...props}
    >
      {props.buttonVariant == 'white' ? (
        <svg
          width='24'
          height='22'
          viewBox='0 0 17 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M2.14844 14.1272L14.5415 1.73413M14.5415 1.73413H2.14844M14.5415 1.73413V14.1272'
            stroke='#121212'
            stroke-width='3'
            stroke-linecap='round'
          />
        </svg>
      ) : (
        <svg
          width='24'
          height='22'
          viewBox='0 0 16 17'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.62891 15.0668L14.2067 2.48901M14.2067 2.48901H1.62891M14.2067 2.48901V15.0668'
            stroke='white'
            stroke-width='3'
            stroke-linecap='round'
          />
        </svg>
      )}
    </DaisyButton>
  );
}
