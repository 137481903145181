/**
 * helpers.ts
 * ---------
 * this module provides a centralised point for managing helper functions within our learner app.
 *
 * these functions are used to perform common tasks, such as formatting strings, or numerical checks.
 *
 * how to use
 * ----------
 * to use a helper function, you can simply import the function and use it!
 *
 * e.g. capitaliseFirstLetter('testjoot') // returns 'Testjoot'
 *
 * how to add new helpers
 * ---------------------
 * to add a new helper function, simply add a new function to this file!
 *
 * ensure that the function is exported, and that it is documented with a description of what it does!
 *
 * things to keep in mind
 * ----------------------
 * - always ensure that new helper functions are added with a clear and descriptive name.
 * - update any documentation when applicable :)
 **/

/**
 * capitalise the first letter of a string
 */
export const capitaliseFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

/**
 * convert a fraction to a percentage string
 */
export const convertFractionToPercentageString = (
  numerator: number,
  denominator: number,
): `${number}%` => `${Math.round((100 * numerator) / denominator)}%`;

/**
 * format a date into a human-readable string
 */
export const formatDateWithSuffix = (date: Date) => {
  const getDaySuffix = (day: number) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // we extract the day from the date
  const day = date.getDate();
  // we then get the correct suffix for that day
  const suffix = getDaySuffix(day);

  // finally, we return the formatted date string with the correctly suffixed day
  return `${new Intl.DateTimeFormat('en-GB', { weekday: 'long' }).format(
    date,
  )}, ${day}${suffix} ${new Intl.DateTimeFormat('en-GB', { month: 'long' }).format(
    date,
  )} ${date.getFullYear()}`;
};
