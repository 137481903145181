import { HiChevronLeft, HiMiniBars3, HiMiniUser } from 'react-icons/hi2';

import { IconContext } from 'react-icons';
import clsx from 'clsx';

type IconButtonTypes = 'CHEVRON_LEFT' | 'HAMBURGER' | 'AVATAR';
export type IconButtonThemes = 'LIGHT' | 'DARK';

interface IconButtonProps {
  type: IconButtonTypes;
  onClick: () => void;
  iconOverride?: JSX.Element;
  theme?: IconButtonThemes;
  size?: number;
}

function IconButton({
  type,
  onClick,
  iconOverride,
  theme,
  size,
}: IconButtonProps) {
  const iconColour = getIconColourFromTheme(theme);
  const iconSize: number = size ? size : 40;
  const iconSizeInPx = `${iconSize}px`;

  return (
    <IconContext.Provider value={{ color: iconColour, size: iconSizeInPx }}>
      <button
        className={clsx(
          `border-${iconColour}`,
          `flex align-center rounded-full border-2 w-[${iconSizeInPx}] h-[${iconSizeInPx}] justify-center items-center hover:bg-black hover:bg-opacity-20`
        )}
        {...{ onClick }}
      >
        {iconToRender(type, iconOverride, iconSize)}
      </button>
    </IconContext.Provider>
  );
}

const getIconColourFromTheme = (theme: IconButtonThemes | undefined) => {
  switch (theme) {
    case 'LIGHT':
      return 'black';
    case 'DARK':
      return 'white';
    default:
      return 'black';
  }
};

const iconToRender = (
  type: IconButtonProps['type'],
  iconOverride: IconButtonProps['iconOverride'],
  iconSize: number
) => {
  if (iconOverride) return iconOverride;

  switch (type) {
    case 'CHEVRON_LEFT':
      return <HiChevronLeft size={iconSize - 10} />;
    case 'HAMBURGER':
      // We scale down the menu icon otherwise it becomes too large for the container
      return <HiMiniBars3 size={iconSize - 15} />;
    case 'AVATAR':
      return <HiMiniUser size={iconSize - 10} />;
    default:
      break;
  }
};

export default IconButton;
