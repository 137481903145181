import { ButtonProps as DaisyButtonProps } from 'react-daisyui';
import BackButton from './BackButton';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import GhostButton from './GhostButton';
import ArrowButton from './ArrowButton';
import NavigationButton from './NavigationButton';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'ghost'
  | 'back'
  | 'arrow-corner-white'
  | 'arrow-corner-black'
  | 'navigation-left'
  | 'navigation-right';

// Allows custom functional and aesthetic preferences to be used on
// top of the existing daisyui component attiributes
export type ButtonProps = Omit<DaisyButtonProps, 'className'> &
  Omit<DaisyButtonProps, 'shape'> & {
    className?: string;
    buttonType?: ButtonType;
    isLoading?: boolean;
  };

export default function Button({
  buttonType,
  ...props
}: ButtonProps): JSX.Element {
  switch (buttonType) {
    case 'primary':
      return <PrimaryButton {...props} />;
    case 'secondary':
      return <SecondaryButton {...props} />;
    case 'ghost':
      return <GhostButton {...props} />;
    case 'back':
      return <BackButton {...props} />;
    case 'arrow-corner-white':
      return <ArrowButton {...props} buttonVariant='white' />;
    case 'arrow-corner-black':
      return <ArrowButton {...props} buttonVariant='black' />;
    case 'navigation-left':
      return <NavigationButton {...props} direction='left' />;
    case 'navigation-right':
      return <NavigationButton {...props} direction='right' />;
    default:
      return <PrimaryButton {...props} />;
  }
}
