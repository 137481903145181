import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Dropdown } from 'react-daisyui';
import { HiLogout } from 'react-icons/hi';

import { useAuthContext } from '@/contexts/AuthContext';
import { StaticRoutes } from '@/utils/links';
import IconButton from '@/components/IconButton';
import { IconButtonThemes } from '@/components/IconButton/IconButton';

export interface NavLink {
  name: string;
  link: string;
}

interface HamburgerMenuProps {
  navLinks: NavLink[];
  showLogoutOption?: boolean;
  iconTheme?: IconButtonThemes;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({
  navLinks,
  showLogoutOption,
  iconTheme,
}) => {
  // accessing handler to log out user
  const [, { logOutUser }] = useAuthContext();

  const router = useRouter();

  const handleLogoutClick = () => {
    // logs the user out of the realm app
    logOutUser();

    // redirect to the login page
    router.push(StaticRoutes.Login);
  };

  return (
    <Dropdown horizontal='left' vertical='bottom'>
      {/* Container needed to ensure the dropdown doesn't compact the icon */}
      <div className='w-[40px] h-[40px]'>
        <IconButton type='HAMBURGER' onClick={() => {}} theme={iconTheme} />
      </div>

      {/* Menu that displays, when you click the hamburger button */}
      <Dropdown.Menu className='menu-sm w-52 mt-3 z-[1] text-black'>
        {navLinks.map((navLink, idx) => {
          return (
            <Link legacyBehavior key={idx} href={navLink.link}>
              <Dropdown.Item>{navLink.name}</Dropdown.Item>
            </Link>
          );
        })}

        {showLogoutOption && (
          <Dropdown.Item className='text-red-500' onClick={handleLogoutClick}>
            <HiLogout className='inline-block' />
            Logout
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default HamburgerMenu;
