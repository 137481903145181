import clsx from 'clsx';
import { ButtonProps } from './Button';
import { Button as DaisyButton } from 'react-daisyui';

export default function SecondaryButton({
  className,
  isLoading,
  ...props
}: ButtonProps) {
  return (
    <>
      <DaisyButton
        className={clsx(
          `btn-secondary font-bold border-black border-2 py-3 w-full font-gtflexa-bold ${className}`,
          isLoading ? 'loading loading-spinner disabled' : ''
        )}
        shape='circle'
        {...props}
      />
    </>
  );
}
