import React from 'react';
import clsx from 'clsx';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi2';
import { Button as DaisyButton } from 'react-daisyui';
import { ButtonProps } from './Button';

interface NavigationButtonProps extends ButtonProps {
  direction: 'left' | 'right';
}

const NavigationButton: React.FC<NavigationButtonProps> = ({
  direction,
  className,
  ...props
}) => {
  const Icon = direction === 'left' ? HiChevronLeft : HiChevronRight;

  return (
    <DaisyButton
      className={clsx(
        ` bg-white text-black border-none no-animation hover:bg-white ${className}`
      )}
      {...props}
      shape='circle'
    >
      <Icon size={32} className='text-black' />
    </DaisyButton>
  );
};

export default NavigationButton;
