import { Button as DaisyButton } from 'react-daisyui';
import { HiChevronLeft } from 'react-icons/hi2';
import { ButtonProps } from './Button';

export default function BackButton(props: ButtonProps): JSX.Element {
  return (
    <DaisyButton
      className='
            rounded-full
            font-bold
            border-2
            border-solid
            border-secondary'
      shape='circle'
      {...props}
    >
      {<HiChevronLeft color='white' />}
    </DaisyButton>
  );
}
