import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useWindowSize } from 'usehooks-ts';

export type BackgroundColourStyle = 'primary' | 'secondary' | 'grey';

interface HeaderBackgroundProps {
  backgroundColourStyle: string;
  isSticky?: boolean;
}

export function HeaderBackground({
  backgroundColourStyle,
  isSticky = false,
  children,
}: PropsWithChildren<HeaderBackgroundProps>) {
  const { width } = useWindowSize();

  const isWeb = width && width > 1024;
  const isWebXL = width && width > 1440;

  const getBackgroundColour = (backgroundColourStyle: string) => {
    switch (backgroundColourStyle) {
      case 'primary':
        return '#D2FF00';
      case 'secondary':
        return '#004140';
      case 'grey':
        return '#DADADA';
      default:
        return '#D2FF00';
    }
  };
  const backgroundWidth = isWebXL ? '250rem' : isWeb ? '180rem' : '100rem';
  const backgroundHeight = isWebXL ? '150rem' : isWeb ? '150rem' : '100rem';

  const CircleBackground: React.FC = () => (
    <div
      style={{
        width: backgroundWidth,
        height: backgroundHeight,
        backgroundColor: getBackgroundColour(backgroundColourStyle),
        border: backgroundColourStyle === 'grey' ? '2px solid #004140' : 'none',
        borderRadius: '100%',
        position: 'absolute',
        left: '50%',
        bottom: 0,
        transform: `translateX(-50%)`,
        zIndex: 10,
        boxShadow: '2px 2px 50px rgba(0, 0, 0, 0.24)',
      }}
    ></div>
  );

  return (
    <div
      className={clsx(
        isSticky ? 'sticky top-0' : 'relative',
        'h-[20%] min-h-[150px]'
      )}
    >
      <div className='relative h-[20%] min-h-[150px] sm:min-h-[200px]'>
        <CircleBackground />
        <div className='absolute w-full h-full z-20 bottom-0 pb-4 sm:pb-10'>
          {children}
        </div>
    </div>
  </div> 
  );
}
