/**
 * links.ts
 * ---------
 * this module provides a centralised point for managing client-side routing paths within our learner app.
 *
 * it uses typescript to enforce type safety for dynamic route generation functions, as well as define
 * static routes for our app.
 *
 * how to use
 * ----------
 * static routes:
 * these are predefined paths used for navigation to static pages.
 *
 * to use a static route, you can simply import the `StaticRoutes` enum and use its members.
 * e.g. <Link href={StaticRoutes.Home}>Go back to home</Link>
 *
 * dynamic routes:
 * these are functions that generate paths for dynamic pages, such as lessons.
 * e.g.
 * <Link href={lessonRoutes.view('iaa-phase-0-lesson-1')}>View first lesson on retrofit!</Link>
 *
 * how to add new routes
 * ---------------------
 * static routes:
 * add a new member to the `StaticRoutes` enum for each static path.
 * e.g. Settings = '/settings'
 *
 * dynamic routes:
 * add a new function to the either an existing or new dynamic route object, following the `LessonRouteFunction` signature for reference.
 * e.g. complete: (lessonSlug: string) => `/lessons/${lessonSlug}/complete`
 *
 * things to keep in mind
 * ----------------------
 * - always ensure that new routes are added with a clear and descriptive name.
 * - update any documentation when applicable :)
 **/

import { NavLink } from '@/components/Header/components/HamburgerMenu';

type LessonRouteFunction = (lessonSlug: string) => string;

// static client-side routes
export enum StaticRoutes {
  Home = '/',
  Welcome = '/welcome',
  Login = '/login',
  Signup = '/signup',
  JobTrainingInterestCompletion = '/job-training-interest/complete',
  ResetPassword = '/reset-password',

  // user onboarding
  Onboarding = '/onboarding',
  FinishYourProfile = '/onboarding/finish-your-profile',
  OnboardingComplete = '/onboarding/complete',

  // dashboard
  LiveOpportunities = '/opportunities',

  // FIXME: home is technically still the courses dashboard, we need
  // to update this route before we release
  // this dashboard to production - this will be /learn in future
  LearningCourses = '/learn',
  ExploreLearningCourses = '/learn/explore',
  JobDiscovery = '/discover',
  Profile = '/profile',
}

export const hamburgerMenuLinks: NavLink[] = [
  {
    name: 'Home',
    link: StaticRoutes.Home,
  },
  {
    name: 'Live Opportunities',
    link: StaticRoutes.LiveOpportunities,
  },
  {
    name: 'Learning Courses',
    link: StaticRoutes.ExploreLearningCourses,
  },
];

// dynamic route function to point to a lesson
export const lessonRoutes: { view: LessonRouteFunction } = {
  view: (lessonSlug: string) => `/lessons/${lessonSlug}`,
};

// API Routes ---------------------
export const APIRoutes = {
  // User
  user: (userUid: string) => `/api/user/${userUid}`,
  userWorkPreferences: (userUid: string) =>
    `/api/user/${userUid}/work-preferences`,
  userInterestInOpportunities: (userUid: string) =>
    `/api/user/${userUid}/work-preferences/opportunity`,
  userDemographics: '/api/user/demographics',
};
