import React from 'react';
import { HeaderBackground } from '../components/HeaderBackground';
import HamburgerMenu from '../components/HamburgerMenu';
import { StaticRoutes } from '@/utils/links';
import { capitaliseFirstLetter } from '@/utils/helpers';

import { hamburgerMenuLinks } from '@/utils/links';
import { useRouter } from 'next/router';
import { useLearnerContext } from '@/contexts/LearnerContext';
import IconButton from '@/components/IconButton';

export const HomeHeader: React.FC = () => {
  const [{ isLoading, learner }] = useLearnerContext();
  const firstName = isLoading ? '' : learner?.firstName;

  const userIsLoading = isLoading || learner === undefined || firstName === '';

  const capitalisedFirstName = capitaliseFirstLetter(firstName);

  const isLongFirstName = capitalisedFirstName.length > 6;

  const router = useRouter();

  const handleAvatarClick = () => {
    router.push(StaticRoutes.Profile);
  };

  return (
    <HeaderBackground backgroundColourStyle='primary'>
      <div className='absolute w-full b-0 z-2 p-10'>
        {/* 
        Could put a logo here?
        <img
          src='/logos/black_logo_full.png'
          width={90}
          height={25}
          alt='Greenworkx'
        /> */}
        <div className='flex flex-row justify-between items-center'>
          {/* Greeting */}
          <h3
            className={`font-gtflexa-medium ${
              isLongFirstName ? 'text-lg md:text-2xl' : 'text-xl md:text-2xl'
            }`}
          >
            {userIsLoading ? 'Welcome!' : 'Hey, ' + capitalisedFirstName}
          </h3>

          <div className='flex flex-row gap-3'>
            {/* Profile button */}
            <IconButton type='AVATAR' onClick={handleAvatarClick} />
            <HamburgerMenu navLinks={hamburgerMenuLinks} showLogoutOption />
          </div>
        </div>
      </div>
    </HeaderBackground>
  );
};
